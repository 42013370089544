import {
    ThemeProvider,
} from '@mui/material';

import { theme } from "./modules/layout/utils";
import { Home } from "./modules/home/components";
import './App.css';
import './modules/global/utils/localization';

function App() {
  return (
      <ThemeProvider theme={theme}>
        <Home />
      </ThemeProvider>
  );
}

export default App;

