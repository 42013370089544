const styles = {
    copyRight: theme => ({
        textAlign: 'center',
        padding: theme.spacing(16, 0),
        '&&': {
            fontWeight: 400,
        },
        color: '#18191F',
    }),
};

export default styles;
