const styles = {
  features: {
    marginTop: 16,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 11.5%',
  },
  title: theme => ({
    marginBottom: 2,
    '&&': {
      fontSize: 48,
      lineHeight: '64px',
      fontWeight: 800,
    },
    [theme.breakpoints.down('sm')]: {
      '&&': {
        fontSize: 24,
        lineHeight: '28px',
      },
    },
  }),
  description: theme => ({
    color: '#18191F',
    textAlign: 'center',
    '&&': {
      fontWeight: 400,
    },
    [theme.breakpoints.down('sm')]: {
      '&&': {
        fontSize: 14,
        lineHeight: '22px',
      }
    },
  }),
  feature: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  featureDescription: {
    textAlign: 'center',
    '&&': {
      fontWeight: 400,
    },
  },
  featureTitle: {
    marginTop: 6,
    marginBottom: 2,
    textAlign: 'center',
  },
  icon: {
    width: 32,
    height: 32,
  },
  featureList: theme => ({
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  }),
  topFeature: theme => ({
    [theme.breakpoints.down('sm')]: {
      paddingTop: 6,
    },
  }),
};

export default styles;
