import { createTheme } from '@mui/material';

let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1135,
            lg: 1920,
            xl: 3000,
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif',
        ].join(','),
        allVariants: {
            fontWeight: 500,
        },
    },
    responsiveTypography: {
        textScaleRatio: 1.2,
    },
    spacing: 4,
    components: {
        MuiListItemAvatar: {
            styleOverrides: {
                root: {
                    minWidth: 0,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 24,
                    height: 24,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                MenuProps: {
                    disableScrollLock: true,
                },
            },
        },
    },
});

theme = createTheme(theme, {
    responsiveTypography: {
        textScaleRatio: 1.2,
    },
    typography: {
        h1: {
            fontSize: '29px',
            lineHeight: '138%',
            [theme.breakpoints.between('md', 'lg')]: {
                fontSize: '37px',
                lineHeight: '44px',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '50px',
                lineHeight: '60px',
            },
        },
        h2: {
            fontSize: '24px',
            lineHeight: '135%',
            [theme.breakpoints.between('md', 'lg')]: {
                fontSize: '29px',
                lineHeight: '138%',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '37px',
                lineHeight: '44px',
            },
        },
        h3: {
            fontSize: '21px',
            lineHeight: '135%',
            [theme.breakpoints.between('md', 'lg')]: {
                fontSize: '24px',
                lineHeight: '135%',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '29px',
                lineHeight: '138%',
            },
        },
        h4: {
            fontSize: '19px',
            lineHeight: '135%',
            [theme.breakpoints.between('md', 'lg')]: {
                fontSize: '21px',
                lineHeight: '135%',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '24px',
                lineHeight: '135%',
            },
        },
        h5: {
            fontSize: '18px',
            lineHeight: '135%',
            [theme.breakpoints.between('md', 'lg')]: {
                fontSize: '19px',
                lineHeight: '135%',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '21px',
                lineHeight: '135%',
            },
        },
        h6: {
            fontSize: '17px',
            lineHeight: '140%',
            [theme.breakpoints.between('md', 'lg')]: {
                fontSize: '18px',
                lineHeight: '135%',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '19px',
                lineHeight: '135%',
            },
        },
        subtitle1: {
            fontSize: '16px',
            lineHeight: '150%',
        },
        subtitle2: {
            fontSize: '14px',
            lineHeight: '140%',
        },
        body1: {
            fontSize: '12px',
            lineHeight: '100%',
        },
        body2: {
            fontSize: '10px',
            lineHeight: '120%',
        },
    },
    components: {
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: 24,
                    padding: theme.spacing(1),
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1, 3),
                    fontSize: theme.typography.subtitle2.fontSize,
                    lineHeight: theme.typography.subtitle2.lineHeight,
                    border: 'none',
                    color: theme.palette.common.black,
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    '&.Mui-selected': {
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '100px !important',
                    },
                    '&.Mui-selected:hover': {
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.primary.main,
                    },
                    '&:hover': {
                        borderRadius: '100px !important',
                    },
                },
            },
        },
    },
});

export default theme;
