const styles = {
  headline: theme => ({
    padding: '64px 11.5% 64px 48px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 0)
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
    '@media screen and (min-width: 1500px)': {
      padding: '0 11.5%',
    }
  }),
  lastHeadline: theme => ({
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  }),
  title: theme => ({
    fontSize: 48,
    lineHeight: '54px',
    fontWeight: 800,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '28px',
      textAlign: 'center',
    },
  }),
  description: theme => ({
    fontWeight: 400,
    color: '#18191F',
    margin: theme.spacing(8, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '22px',
      textAlign: 'center',
    },
  }),
  content: theme => ({
    paddingLeft: theme.spacing(0, 12),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  icon: {
    fill: '#276EF1',
  },
  bottomImage: theme => ({
    paddingLeft: '15%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  }),
  media: {
    width: '100%',
  },
  image: theme => ({
    position: 'absolute',
    bottom: 0,
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('sm')]: {
      top: 50,
      width: 213,
    }
  }),
  getStarted:  theme => ({
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    },
  }),
  headlineImg: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    background: 'rgba(39, 110, 241, 0.2)',
    height: 540,
    width: 540,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 320,
    }
  }),
  headlineWrapper: {
    margin: '64px 0',
  }
};

export default styles;
