import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import {
    Feature1,
    Feature3,
    Feature5
} from "../../../global";
import styles from './styles';
import installationImage from '../../../../assets/images/installation-mock.jpg';

const Installation = () => {
    return <Box sx={styles.installation}>
        <Grid container direction='row'>
            <Grid item sx={styles.left}>
                <Typography variant='h1' sx={styles.installationDescription}>
                    Install the device with few easy steps.
                </Typography>
                <Box
                    component='img'
                    src={installationImage}
                    alt='Installation'
                    width={445}
                    height={525}
                    sx={styles.installationImage}
                    loading='lazy'
                />
            </Grid>
            <Grid item sx={styles.steps}>
                <Box sx={[styles.step, styles.firstStep]}>
                    <Box sx={styles.circle}>
                        <Feature1 />
                    </Box>
                    <Box sx={styles.content}>
                        <Typography variant='subtitle2' sx={[styles.stepNo, styles.firstStepNo]}>STEP 1</Typography>
                        <Typography variant='subtitle2' sx={[styles.stepDescription, styles.firstStepDescription]}>
                            Order a device from us, or connect compatible ones, if you already have.
                        </Typography>
                    </Box>
                </Box>
                <Box sx={[styles.step, styles.midStep]}>
                    <Box sx={styles.circle}>
                        <Feature3 />
                    </Box>
                    <Box sx={styles.content}>
                        <Typography variant='subtitle2' sx={styles.stepNo}>STEP 1</Typography>
                        <Typography variant='subtitle2' sx={styles.stepDescription}>
                            Map the device to your asset in the web UI.
                        </Typography>
                    </Box>
                </Box>
                <Box sx={styles.step}>
                    <Box sx={styles.circle}>
                        <Feature5 />
                    </Box>
                    <Box sx={styles.content}>
                        <Typography variant='subtitle2' sx={styles.stepNo}>STEP 1</Typography>
                        <Typography variant='subtitle2' sx={styles.stepDescription}>
                            Start receiving data.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    </Box>;
}

export default Installation;

