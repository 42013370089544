import {
  Box,
  Typography,
  Grid,
} from '@mui/material';
import styles from './styles';
import {
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature5,
  Feature6,
} from '../../../global';
import {useTranslation} from "react-i18next";

const Features = function () {
  const { t } = useTranslation();

  return (
    <Box sx={styles.features}>
      <Typography variant='h1' sx={styles.title}>Tailor-made features</Typography>
      {/*<Typography variant='h6' sx={styles.description}>*/}
      {/*  Lorem ipsum is common placeholder text used to demonstrate the*/}
      {/*  <br />*/}
      {/*  {' '}*/}
      {/*  graphic elements of a document or visual presentation.*/}
      {/*</Typography>*/}
      <Grid
        container
        rowSpacing={16}
        columnSpacing={7}
        mt={10}
        sx={styles.featureList}
      >
        <Grid
          md={4}
          sm={6}
          item
          xs={12}
          sx={[styles.feature, styles.topFeature]}
        >
          <Feature1 sx={styles.icon} />
          <Typography variant='h3' sx={styles.featureTitle}>{t('features.liveMonitoring.label')}</Typography>
          <Typography sx={styles.featureDescription} variant='subtitle1'>
            {t('features.liveMonitoring.description')}
          </Typography>
        </Grid>
        <Grid
          md={4}
          sm={6}
          item
          xs={12}
          sx={styles.feature}
        >
          <Feature2 sx={styles.icon} />
          <Typography variant='h3' sx={styles.featureTitle}>{t('features.history.label')}</Typography>
          <Typography sx={styles.featureDescription} variant='subtitle1'>
            {t('features.history.description')}
          </Typography>
        </Grid>
        <Grid
          md={4}
          sm={6}
          item
          xs={12}
          sx={styles.feature}
        >
          <Feature3 sx={styles.icon} />
          <Typography variant='h3' sx={styles.featureTitle}>{t('features.behavior.label')}</Typography>
          <Typography sx={styles.featureDescription} variant='subtitle1'>
            {t('features.behavior.description')}
          </Typography>
        </Grid>
        <Grid
          md={4}
          sm={6}
          item
          xs={12}
          sx={styles.feature}
        >
          <Feature4 sx={styles.icon} />
          <Typography variant='h3' sx={styles.featureTitle}>{t('features.sensor.label')}</Typography>
          <Typography sx={styles.featureDescription} variant='subtitle1'>
            {t('features.sensor.description')}
          </Typography>
        </Grid>
        <Grid
          md={4}
          sm={6}
          item
          xs={12}
          sx={styles.feature}
        >
          <Feature5 sx={styles.icon} />
          <Typography variant='h3' sx={styles.featureTitle}>{t('features.dispatching.label')}</Typography>
          <Typography sx={styles.featureDescription} variant='subtitle1'>
            {t('features.dispatching.description')}
          </Typography>
        </Grid>
        <Grid
          md={4}
          sm={6}
          item
          xs={12}
          sx={styles.feature}
        >
          <Feature6 sx={styles.icon} />
          <Typography variant='h3' sx={styles.featureTitle}>{t('features.proofOfDelivery.label')}</Typography>
          <Typography sx={styles.featureDescription} variant='subtitle1'>
            {t('features.proofOfDelivery.description')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
