import {
  Box,
  Grid,
  Typography, useMediaQuery,
} from '@mui/material';
import styles from './styles';
import { QuoteMarkIcon } from '../../../global';

const Stories = function () {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  return (
    <Box sx={styles.stories}>
      <Box sx={styles.content}>
        <Typography variant='h1' sx={styles.title}>Real Stories from Real Customers</Typography>
        <Typography variant='h5' sx={styles.description}>Get inspired by these stories.</Typography>
        <QuoteMarkIcon sx={styles.quoteMark} />
      </Box>
      <Grid
        container
        columnSpacing={7.5}
        rowSpacing={2}
        pl={48}
        pt={6}
        sx={styles.storyList}
      >
        <Grid
          md={4}
          sm={12}
          item
          sx={styles.story}
        >
          <QuoteMarkIcon sx={styles.quoteMarkSmall} />
          <Typography variant='h5' sx={styles.storyDescription}>
            To quickly start my startup landing page design, I was looking
            for a landing page UI Kit. Landify is one of the best landing page UI kit I
            have come across. It’s so flexible, well organised and easily editable.
          </Typography>
          <Box sx={styles.author}>
            <Typography variant='h5' sx={styles.storyAuthor}>Floyd Miles</Typography>
            <Typography variant='subtitle2' sx={styles.storyAuthorCompany}>Vice President, GoPro</Typography>
          </Box>
        </Grid>
        <Grid
          item md={6} sm={12}
          sx={styles.seperatedStories}
        >
          <Grid container direction={isSmallScreen ? 'column' : 'row'}>
            <Grid
              item
              xs={12}
              sm={12}
              sx={[styles.story, styles.position]}
            >
              <QuoteMarkIcon sx={styles.quoteMarkSmall} />
              <Typography variant='h5' sx={styles.storyDescription}>
                I used landify and created a landing page for my startup within a week.
                The Landify UI Kit is simple and highly intuitive, so anyone can use it.
              </Typography>
              <Box sx={styles.author}>
                <Typography variant='h5' sx={styles.storyAuthor}>Jane Cooper</Typography>
                <Typography variant='subtitle2' sx={styles.storyAuthorCompany}>CEO, Airbnb</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={10}
              sm={12}
              sx={styles.story}
            >
              <QuoteMarkIcon sx={styles.quoteMarkSmall} />
              <Typography variant='h5' sx={styles.storyDescription}>
                Landify saved our time in designing my company page.
              </Typography>
              <Box sx={styles.author}>
                <Typography variant='h5' sx={styles.storyAuthor}>Kristin Watson</Typography>
                <Typography variant='subtitle2' sx={styles.storyAuthorCompany}>Co-Founder, BookMyShow</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Stories;
