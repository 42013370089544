import {
  Box,
  Button,
  Divider,
  Grid,
  Typography, useMediaQuery,
} from '@mui/material';
import styles from './styles';
import {
  ArrowIcon,
  CheckIcon,
  EuroIcon,
  WhiteCheckIcon,
} from '../../../global';

const Pricing = function () {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  return (
    <Box sx={styles.pricing}>
      <Typography variant='h1' sx={styles.title}>
        Simple & Transparent Pricing
      </Typography>
      <Typography style={{
        fontSize: 20,
        textAlign: 'center',
        marginTop: 4,
      }}>
        We are currently working on our pricing packages, but you can always contact us and we will calculate our best offer depending on your business needs.
      </Typography>
      {/*<Grid container*/}
      {/*      sx={styles.packages}*/}
      {/*      direction={isSmallScreen ? 'column' : 'row'}*/}
      {/*      wrap='nowrap'*/}
      {/*>*/}
      {/*  <Grid*/}
      {/*    item*/}
      {/*    sx={styles.package}*/}
      {/*    xs={isSmallScreen ? 12 : 4}*/}
      {/*  >*/}
      {/*    <Typography variant='h1' sx={styles.packageTitle}>Personal</Typography>*/}
      {/*    <Box sx={styles.price}>*/}
      {/*      <EuroIcon sx={styles.euro} />*/}
      {/*      <Typography variant='h5' sx={styles.priceInNum}>12,99</Typography>*/}
      {/*      <Typography variant='body2' sx={styles.perUser}>*/}
      {/*        / user*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*    <Divider sx={styles.divider} />*/}
      {/*    <Grid*/}
      {/*      container*/}
      {/*      spacing={5}*/}
      {/*      sx={styles.terms}*/}
      {/*    >*/}
      {/*      <Grid item>*/}
      {/*        <Box sx={styles.point}>*/}
      {/*          <CheckIcon sx={styles.checkIcon} />*/}
      {/*          <Typography variant='subtitle2' sx={styles.description}>Create personal dashboard.</Typography>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*      <Grid item>*/}
      {/*        <Box sx={styles.point}>*/}
      {/*          <CheckIcon sx={styles.checkIcon} />*/}
      {/*          <Typography variant='subtitle2' sx={styles.description}>Organize your notes and workflows.</Typography>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*      <Grid item>*/}
      {/*        <Box sx={styles.point}>*/}
      {/*          <CheckIcon sx={styles.checkIcon} />*/}
      {/*          <Typography variant='subtitle2' sx={styles.description}>5GB of space.</Typography>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*    <Button*/}
      {/*      endIcon={<ArrowIcon />}*/}
      {/*      variant='contained'*/}
      {/*      sx={styles.choosePlan}*/}
      {/*    >*/}
      {/*      Choose this plan*/}
      {/*    </Button>*/}
      {/*  </Grid>*/}
      {/*  <Grid*/}
      {/*    item sx={[styles.package, styles.premiumPackage]}*/}
      {/*    xs={isSmallScreen ? 12 : 4}*/}
      {/*  >*/}
      {/*    <Button variant='contained' sx={styles.discount}>-30%</Button>*/}
      {/*    <Typography variant='h1' sx={[styles.packageTitle, styles.premiumPackageTitle]}>Pro Plan</Typography>*/}
      {/*    <Box sx={styles.price}>*/}
      {/*      <EuroIcon sx={styles.euro} />*/}
      {/*      <Typography variant='h5' sx={[styles.priceInNum, styles.premiumPackagePrice]}>24,90</Typography>*/}
      {/*      <Typography variant='body2' sx={styles.perUser}>*/}
      {/*        / user*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*    <Divider sx={styles.divider} />*/}
      {/*    <Grid*/}
      {/*      container*/}
      {/*      spacing={5}*/}
      {/*      sx={styles.terms}*/}
      {/*    >*/}
      {/*      <Grid item>*/}
      {/*        <Box sx={styles.point}>*/}
      {/*          <WhiteCheckIcon sx={styles.checkIcon} />*/}
      {/*          <Typography variant='subtitle2' sx={styles.description}>All features in Personal.</Typography>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*      <Grid item>*/}
      {/*        <Box sx={styles.point}>*/}
      {/*          <WhiteCheckIcon sx={styles.checkIcon} />*/}
      {/*          <Typography variant='subtitle2' sx={styles.description}>Unclock Teams to create a work group.</Typography>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*      <Grid item>*/}
      {/*        <Box sx={styles.point}>*/}
      {/*          <WhiteCheckIcon sx={styles.checkIcon} />*/}
      {/*          <Typography variant='subtitle2' sx={styles.description}>20GB of shared space.</Typography>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*    <Button*/}
      {/*      endIcon={<ArrowIcon />}*/}
      {/*      variant='contained'*/}
      {/*      sx={styles.choosePlan}*/}
      {/*    >*/}
      {/*      Choose this plan*/}
      {/*    </Button>*/}
      {/*  </Grid>*/}
      {/*  <Grid*/}
      {/*    item sx={styles.package}*/}
      {/*    xs={isSmallScreen ? 12 : 4}*/}
      {/*  >*/}
      {/*    <Typography variant='h1' sx={styles.packageTitle}>Enterprise</Typography>*/}
      {/*    <Box sx={styles.price}>*/}
      {/*      <EuroIcon sx={styles.euro} />*/}
      {/*      <Typography variant='h5' sx={styles.priceInNum}>54,90</Typography>*/}
      {/*      <Typography variant='body2' sx={styles.perUser}>*/}
      {/*        / user*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*    <Divider sx={styles.divider} />*/}
      {/*    <Grid*/}
      {/*      container*/}
      {/*      spacing={5}*/}
      {/*      sx={styles.terms}*/}
      {/*    >*/}
      {/*      <Grid item>*/}
      {/*        <Box sx={styles.point}>*/}
      {/*          <CheckIcon sx={styles.checkIcon} />*/}
      {/*          <Typography variant='subtitle2' sx={styles.description}>All features in Pro Plan.</Typography>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*      <Grid item>*/}
      {/*        <Box sx={styles.point}>*/}
      {/*          <CheckIcon sx={styles.checkIcon} />*/}
      {/*          <Typography variant='subtitle2' sx={styles.description}>Unclock Database to manage your data.</Typography>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*      <Grid item>*/}
      {/*        <Box sx={styles.point}>*/}
      {/*          <CheckIcon sx={styles.checkIcon} />*/}
      {/*          <Typography variant='subtitle2' sx={styles.description}>500GB / 5 shared spaces.</Typography>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*    <Button*/}
      {/*      endIcon={<ArrowIcon />}*/}
      {/*      variant='contained'*/}
      {/*      sx={styles.choosePlan}*/}
      {/*    >*/}
      {/*      Choose this plan*/}
      {/*    </Button>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </Box>
  );
};

export default Pricing;
