import Theme from "../../../layout/utils/theme";

const styles = {
    installation: theme => ({
        padding: '64px 11.5%',
        '@media (max-width:1270px)': {
            padding: '64px 5%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '56px 0 0',
        }
    }),
    installationDescription: theme => ({
        '&&': {
            fontWeight: 800,
            fontSize: 48,
            lineHeight: '64px',
            color: '#18191F',
        },
        maxWidth: 445,
        [theme.breakpoints.down('sm')]: {
            '&&': {
                fontSize: 24,
                lineHeight: '28px',
                textAlign: 'center',
                alignSelf: 'center',
            },
            maxWidth: 270,
            marginBottom: 6,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 'unset',
            alignSelf: 'flex-start',
        }
    }),
    image: (theme) => ({
        width: 445,
        height: 525,
    }),
    step: theme => ({
        padding: theme.spacing(12, 20),
        backgroundColor: 'rgba(39, 110, 241, 0.2)',
        display: 'flex',
        '@media (max-width:1500px)': {
            padding: theme.spacing(12, 10),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(12, 20),
        },
        '@media (max-width:750px)': {
            padding: theme.spacing(12, 10),
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            padding: 6,
            width: 'inherit',
            margin: theme.spacing(0, 6),
        },
    }),
    firstStep: theme => ({
        backgroundColor: 'primary.main',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'rgba(39, 110, 241, 0.2)',
        },
    }),
    circle: {
        width: 96,
        height: 96,
        borderRadius: '50%',
        backgroundColor: '#F4F5F7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    firstStepNo: {
      color: '#fff',
    },
    stepNo: theme => ({
        color: 'primary.main',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: 24,
            lineHeight: '28px',
            marginTop: 6,
            color: '#18191F',
            fontWeight: 800,
        },
    }),
    stepDescription: theme => ({
        maxWidth: 300,
        color: '#18191F',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            color: '#18191F',
            textAlign: 'center',
        },
    }),
    firstStepDescription: {
      color: '#fff'
    },
    content: theme => ({
        marginLeft: theme.spacing(20),
        '@media (max-width:1500px)': {
            marginLeft: theme.spacing(5),
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(20),
        },
        '@media (max-width:750px)': {
            marginLeft: theme.spacing(5),
        },
    }),
    steps: theme => ({
        marginLeft: theme.spacing(8),
        flexGrow: 2,
        marginTop: theme.spacing(19),
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        },
    }),
    left: theme => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    }),
    installationImage: theme => ({
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'inherit',
        },
        marginTop: 4,
    }),
    midStep: theme => ({
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(6, 0),
        },
        [theme.breakpoints.down('sm')]: {
            margin: 6,
        },
    }),
};

export default styles;
