import {AppBar, Box, Button, MenuItem, Select, Toolbar, useMediaQuery} from "@mui/material";
import {LargeLogoIcon} from "../../../global";
import styles from "./styles";
import {useTranslation} from "react-i18next";

const LOGIN_URL = 'https://app.superfleet.ai/login';
const APP_URL = 'https://app.superfleet.ai';

const Header = () => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const { t, i18n } = useTranslation();


    const onChange = (e) => {
        i18n.changeLanguage(e.target.value);
    }

    const language = (i18n.language == 'en-US' || i18n.language == 'en')
     ? 'en' : 'hy';

    return (
        <AppBar sx={styles.appBar}>
            <Toolbar sx={styles.toolBar}>
                <LargeLogoIcon />
                <Box sx={styles.headerButtons}>
                    <Button
                        variant={isSmallScreen ? 'contained' : 'outlined'}
                        sx={[styles.button, styles.login]}
                        href={LOGIN_URL}
                    >{t('header.logIn')}</Button>
                    {!isSmallScreen && (
                        <Button
                            variant='contained'
                            sx={[styles.button, styles.getStarted]}
                            href={APP_URL}
                        >{t('header.getStarted')}</Button>
                    )}
                </Box>
                {/*<Select*/}
                {/*    value={language}*/}
                {/*    sx={styles.select}*/}
                {/*    onChange={onChange}*/}
                {/*>*/}
                {/*    <MenuItem value='en' sx={styles.menuItem}>{t('languages.en')}</MenuItem>*/}
                {/*    <MenuItem value='hy' sx={styles.menuItem}>{t('languages.hy')}</MenuItem>*/}
                {/*</Select>*/}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
