import { Typography } from "@mui/material";
import styles from './styles';

const CopyRight = () => <Typography
    sx={styles.copyRight}
    variant='subtitle2'
>
    Copyright © 2022 Superfleet. All rights reserved
</Typography>;

export default CopyRight;
