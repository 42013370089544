import {
  Box,
  Divider,
} from '@mui/material';
import {
    lazy,
    Suspense,
} from "react";
import Main from '../Main';
import {
    CopyRight,
    Features,
    Header,
} from '../index';
import styles from './styles';

const Stories = lazy(() => import('../Stories'));
const Headline = lazy(() => import('../Headline'));
const Pricing = lazy(() => import('../Pricing'));
const Installation = lazy(() => import('../Installation'));

const LoadingState = (<div>Loading...</div>);

const Home = function () {
  return (
    <Box>
      <Header />
      <Main />
      <Features />
      {/*<Suspense fallback={LoadingState}>*/}
      {/*    <Stories />*/}
      {/*</Suspense>*/}
      <Suspense fallback={LoadingState}>
        <Headline />
      </Suspense>
      <Suspense fallback={LoadingState}>
        <Pricing />
      </Suspense>
      <Suspense fallback={LoadingState}>
        <Installation />
      </Suspense>
      <Divider sx={styles.divider}/>
      <CopyRight />
    </Box>
  );
};

export default Home;
