const styles = {
  pricing: theme => ({
    padding: '64px 11.5%',
    backgroundColor: '#D4E2FC',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '64px 24px',
    },
  }),
  title: theme => ({
    '&&': {
      fontSize: 48,
      lineHeight: '64px',
      fontWeight: 800,
    },
    [theme.breakpoints.down('md')]: {
      '&&': {
        fontSize: 24,
        lineHeight: '28px',
        textAlign: 'center',
        color: '#18191F',
      }
    },
  }),
  euro: {
    width: 9,
    height: 11,
  },
  price: {
    display: 'flex',
    alignItems: 'center',
  },
  priceInNum: theme => ({
    margin: theme.spacing(0, 2),
  }),
  package: (theme) => ({
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px rgba(41, 41, 42, 0.07)',
    borderRadius: 2,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'fit-content',
    '&&': {
      maxWidth: 500,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  }),
  packageTitle: {
    color: '#4D4D4D',
    marginBottom: 5,
  },
  divider: theme => ({
    margin: theme.spacing(8, 0),
    width: '100%',
  }),
  checkIcon: {
    width: 19,
    height: 18,
    fill: 'none',
  },
  point: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    marginLeft: 1,
    color: '#AEAEAE',
  },
  terms: {
    flexDirection: 'column',
  },
  choosePlan: {
    borderRadius: 'unset',
    marginTop: 8,
  },
  premiumPackage: theme => ({
    backgroundColor: '#222',
    margin: theme.spacing(0, 8),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(6, 0),
    },
  }),
  premiumPackageTitle: {
    color: '#fff',
  },
  perUser: {
    color: '#4D4D4D',
  },
  premiumPackagePrice: {
    color: '#fff',
  },
  packages: theme => ({
    marginTop: 14,
    alignItems: 'flex-end',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 6,
    },
  }),
  discount: {
    borderRadius: '10px',
    marginBottom: 4,
  },
};

export default styles;
