const styles = {
  stories: theme => ({
    padding: '4.5% 11.5% 10%',
    background: 'linear-gradient(0deg, rgba(39, 110, 241, 0.2), rgba(39, 110, 241, 0.2)), #FFFFFF',
    [theme.breakpoints.down('md')]: {
      padding: '56px 4%',
    },
  }),
  title: theme => ({
    maxWidth: 445,
    position: 'relative',
    zIndex: 9,
    '&&': {
      fontSize: 48,
      lineHeight: '64px',
      fontWeight: '800',
    },
    [theme.breakpoints.down('md')]: {
      '&&': {
        fontSize: 24,
        lineHeight: '28px',
        textAlign: 'center',
      }
    },
  }),
  description: theme => ({
    color: '#18191F',
    '&&': {
      fontWeight: 400,
    },
    [theme.breakpoints.down('md')]: {
      '&&': {
        fontSize: 14,
        lineHeight: '22px',
        textAlign: 'center',
      }
    },
  }),
  storyDescription: theme => ({
    fontWeight: 400,
    color: '#18191F',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '25px',
    },
  }),
  content: {
    position: 'relative',
    marginLeft: 24,
    marginTop: 21,
  },
  quoteMark: theme => ({
    width: 143,
    height: 120,
    position: 'absolute',
    bottom: 84,
    left: -95,
    [theme.breakpoints.down('sm')]: {
      bottom: 50,
    },
  }),
  story: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 10px 20px rgba(41, 41, 42, 0.07)',
    borderRadius: 2,
    '&&': {
      padding: 8,
      paddingLeft: 16,
    },
    height: 'fit-content',
    minWidth: 300,
    position: 'relative',
  },
  storyAuthor: {
    fontWeight: 700,
  },
  storyAuthorCompany: {
    color: '#969BAB',
  },
  author: {
    marginTop: 6,
  },
  position: theme => ({
    position: 'relative',
    bottom: 104,
    [theme.breakpoints.down('md')]: {
      bottom: 0,
      marginBottom: 2,
    },
  }),
  quoteMarkSmall: {
    position: 'absolute',
    left: 24,
    top: 24,
  },
  seperatedStories: theme => ({
    [theme.breakpoints.down('md')]: {
      '&&': {
        paddingLeft: 0,
      },
    },
  }),
  storyList: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      margin: 0,
      width: '100%',
    },
  }),
};

export default styles;
