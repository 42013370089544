const styles = {
    appBar: {
        backgroundColor: '#fff',
        boxShadow: 'none',
        minHeight: 0,
    },
    toolBar: theme => ({
        height: 60,
        '&&': {
            minHeight: 0,
            padding: '0 11.5%',
        },
        [theme.breakpoints.down('md')]: {
            '&&': {
                padding: '0 2.5%',
            },
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            '&&': {
                minHeight: 0,
                padding: '0 8px',
            },
        }
    }),
    button: {
        minWidth: 130,
        borderRadius: 'unset',
        textDecoration: 'none',
        '&&': {
            fontWeight: 'bold',
        },
    },
    login: theme => ({
        border: '1px solid #1976d2',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 4,
        },
    }),
    getStarted: {
        marginLeft: 4,
    },
    headerButtons: theme => ({
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            flexGrow: 0,
        },
    }),
    select: theme => ({
        marginLeft: 4,
        '& > .MuiSelect-select': {
            paddingTop: 1.5,
            paddingBottom: 1.5,
            ...theme.typography.subtitle2,
            lineHeight: 1.75,
        },
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "pre",
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    }),
    menuItem: theme => ({
        ...theme.typography.subtitle2,
    }),
};

export default styles;
