import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import phoneMockup from '../../../../assets/phone_mockup.jpg';
import styles from './styles';
import { ArrowIcon } from '../../../global';
import headline_avif from "../../../../assets/images/headline.avif";
import headline_webp from "../../../../assets/images/headline.webp";
import headline_png from "../../../../assets/images/courier-app.png";
import mobile_app from '../../../../assets/images/mobile-app.png';
import headline_desktop_png from "../../../../assets/images/headline_desktop.png";
import headline_desktop_avif from "../../../../assets/images/headline_desktop.avif";
import headline_desktop_webp from "../../../../assets/images/headline_desktop.webp";

const Headline = function () {
  return (
    <Box sx={styles.headlineWrapper}>
      <Grid container sx={styles.headline}>
        <Grid item md={7} sx={styles.media}>
          <Box sx={styles.headlineImg}>
            <picture>
              <source
                  type='image/avif'
                  media='(min-width: 600px)'
                  width={320}
                  srcSet={headline_png}
              />
              <source
                  type='image/webp'
                  media='(min-width: 600px)'
                  width={320}
                  srcSet={headline_png}
              />
              <source
                  type='image/png'
                  media='(min-width: 600px)'
                  width={320}
                  srcSet={headline_png}
              />
              <Box
                  component='img'
                  sx={styles.image}
                  src={headline_png}
                  alt='Hedline'
                  height={400}
                  width={320}
                  loading='lazy'
              />
            </picture>
          </Box>
        </Grid>
        <Grid item md={5} sx={styles.content}>
          <Typography sx={styles.title}>Courier app</Typography>
          <Typography variant='h5' sx={styles.description}>
            Starting on delivery business? Courier app can
            automate all your processes, both for couriers and
            for managers.
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={[styles.headline, styles.lastHeadline]}>
        <Grid item md={5} sx={styles.content}>
          <Typography sx={styles.title}>Mobile Application</Typography>
          <Typography variant='h5' sx={styles.description}>
            Real tracking of your fleet with our mobile application. Get important alerts and notification always on time.(Coming Soon)
          </Typography>
        </Grid>
        <Grid item md={7} sx={[styles.media, styles.bottomImage]}>
          <Box sx={styles.headlineImg}>
            <picture>
              <source
                  type='image/avif'
                  media='(min-width: 600px)'
                  width={320}
                  srcSet={mobile_app}
              />
              <source
                  type='image/webp'
                  media='(min-width: 600px)'
                  width={320}
                  srcSet={mobile_app}
              />
              <source
                  type='image/png'
                  media='(min-width: 600px)'
                  width={320}
                  srcSet={mobile_app}
              />
              <Box
                  component='img'
                  sx={styles.image}
                  src={mobile_app}
                  alt='Hedline'
                  height={400}
                  width={320}
                  loading='lazy'
              />
            </picture>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Headline;
