import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import styles from './styles';
import {useTranslation} from "react-i18next";
import app_avif from './../../../../assets/images/app.avif';
import app_webp from './../../../../assets/images/app.webp';
import app_jpg from './../../../../assets/images/app.jpg';

const APP_URL = 'https://app.superfleet.ai';

const Main = function () {
  const { t } = useTranslation();

  return (
    <Box sx={styles.main}>
      <Box sx={styles.content}>
        <Typography variant='h1' sx={styles.heading}>
          {t('main.superfleet')}
          <br />
          {t('main.title')}
        </Typography>
        <Typography variant='h5' sx={styles.description}>
          {t('main.description')}
        </Typography>
        <Button
            variant='contained'
            sx={styles.button}
            href={APP_URL}
        >{t('main.getStarted')}</Button>
      </Box>
      <Box sx={styles.media}>
        <Box sx={styles.circle} />
         <picture>
             <source type='image/avif' srcSet={app_avif} />
             <source type='image/webp' srcSet={app_webp} />
             <Box component='img'
                  src={app_jpg}
                  sx={styles.superfleet}
                  alt='Superfleet in MacBook'
                  width='100%'
             />
         </picture>
      </Box>
    </Box>
  );
};

export default Main;
