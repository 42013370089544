const styles = {
  main: theme => ({
    paddingLeft: '11.5%',
    display: 'flex',
    position: 'relative',
    marginTop: 29,
    marginBottom: 13,
    height: 'calc(100vh - 60px)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      flexDirection: 'column',
      height: 'auto',
    },
  }),
  heading: theme => ({
    maxWidth: 600,
    '&&': {
      fontSize: '4.5rem',
      lineHeight: '6.125rem',
      fontWeight: 800,
    },
    [theme.breakpoints.down('sm')]: {
      '&&': {
        fontSize: 24,
        lineHeight: '28px',
        textAlign: 'center',
      }
    },
  }),
  description: theme => ({
    '&&': {
      fontWeight: 500,
    },
    marginTop: 4,
    [theme.breakpoints.down('sm')]: {
      '&&': {
        fontSize: 14,
        lineHeight: '22px',
        textAlign: 'center',
      },
    },
    whiteSpace: 'pre-line',
}),
  button: (theme) => ({
    borderRadius: 'unset',
    marginTop: 10,
    '&&': {
      fontWeight: 'bold',
      ...theme.typography.h6,
    },
    maxWidth: 400,
  }),
  content: theme => ({
    position: 'absolute',
    zIndex: 9,
    bottom: '43%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'static',
    },
  }),
  media: theme => ({
    marginLeft: 'auto',
    filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.16))',
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexBasis: '60%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginTop: 8,
    },
  }),
  circle: theme => ({
    borderRadius: '50%',
    background: 'rgba(39, 110, 241, 0.2)',
    position: 'relative',
    right: 130,
    flex: 1,
    aspectRatio: '1/1',
    [theme.breakpoints.down('sm')]: {
      right: 0,
      width: '95vw',
      height: '95vw',
    },
  }),
  superfleet: theme => ({
    position: 'absolute',
    right: 0,
    top: '50%',
    bottom: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('sm')]: {
      left: '30%',
    },
  }),
};

export default styles;
