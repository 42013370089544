import {
    SvgIcon,
} from '@mui/material';

export const QuoteMarkIcon = function (props) {
    return (
        <SvgIcon
            viewBox='0 0 143 120'
            {...props}
        >
            <g opacity='0.5' clipPath='url(#clip0_4815_2358)'>
                <path
                    fillRule='evenodd' clipRule='evenodd' d='M142.373 18.7419C121.049 29.1541 110.387 41.3882 110.387 55.4447C119.476 56.4859 126.992 60.1735 132.934 66.5076C138.877 72.8417 141.849 80.1735 141.849 88.5033C141.849 97.3536 138.965 104.816 133.197 110.889C127.428 116.963 120.175 120 111.435 120C101.647 120 93.1701 116.052 86.0037 108.156C78.8374 100.26 75.2542 90.6725 75.2542 79.3926C75.2542 45.553 94.306 19.089 132.41 0L142.373 18.7419ZM67.1186 18.7419C45.6196 29.1541 34.8702 41.3882 34.8702 55.4447C44.134 56.4859 51.7373 60.1735 57.6801 66.5076C63.6229 72.8417 66.5943 80.1735 66.5943 88.5033C66.5943 97.3536 63.6666 104.816 57.8112 110.889C51.9557 116.963 44.6584 120 35.919 120C26.1308 120 17.6973 116.052 10.6184 108.156C3.53942 100.26 0 90.6725 0 79.3926C0 45.553 18.9643 19.089 56.8935 0L67.1186 18.7419Z'
                    fill='#276EF1'
                />
            </g>
            <defs>
                <clipPath id='clip0_4815_2358'>
                    <rect width='143' height='120' fill='white' />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export const ArrowIcon = function (props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <path d='M15.2931 6.70711C14.9026 6.31658 14.9026 5.68342 15.2931 5.29289C15.6837 4.90237 16.3168 4.90237 16.7074 5.29289L22.7073 11.2929C23.0979 11.6834 23.0979 12.3166 22.7073 12.7071L16.7074 18.7071C16.3168 19.0976 15.6837 19.0976 15.2931 18.7071C14.9026 18.3166 14.9026 17.6834 15.2931 17.2929L19.586 13H2.01103C1.45265 13 1 12.5523 1 12C1 11.4477 1.45265 11 2.01103 11H19.586L15.2931 6.70711Z' />
        </SvgIcon>
    );
};

export const EuroIcon = function (props) {
    return (
        <SvgIcon viewBox='0 0 9 11' {...props}>
            <path opacity='0.5' d='M0.5 7.02996H1.98685C2.53083 9.71327 4.5689 11 7.07842 11C7.58613 11 8.19538 10.9372 8.5 10.8274V9.33666C8.19538 9.42297 7.58613 9.47004 7.10743 9.47004C5.4175 9.47004 4.12647 8.6933 3.65503 7.02996H7.59338V6.14337H3.49547C3.48096 5.94722 3.46646 5.74322 3.46646 5.52354V5.50785C3.46646 5.26462 3.48096 5.02924 3.50272 4.80956H7.59338V3.92297H3.67679C4.16274 2.29886 5.43926 1.52996 7.10743 1.52996C7.58613 1.52996 8.19538 1.59272 8.5 1.66334V0.172611C8.19538 0.0706134 7.58613 0 7.07842 0C4.59066 0 2.55984 1.27104 1.99411 3.92297H0.5V4.80956H1.87081C1.84905 5.02924 1.8418 5.25678 1.8418 5.49215V5.50785C1.8418 5.72753 1.84905 5.93937 1.86355 6.14337H0.5V7.02996Z' fill='#4D4D4D' />
        </SvgIcon>
    );
};

export const CheckIcon = function (props) {
    return (
        <SvgIcon viewBox='0 0 19 18' {...props}>
            <circle
                cx='9.5' cy='9' r='9'
                fill='#5A5A5A'
            />
            <path
                d='M5.5 8.94545L8.34444 12L13.5 6' stroke='#D5D5D5' strokeLinecap='round'
                strokeLinejoin='round'
            />
        </SvgIcon>
    );
};

export const WhiteCheckIcon = function (props) {
    return (
        <SvgIcon viewBox='0 0 18 18' {...props}>
            <circle
                cx='9' cy='9' r='9'
                fill='white'
            />
            <path
                d='M5 8.94545L7.84444 12L13 6' stroke='#3F3F3F' strokeLinecap='round'
                strokeLinejoin='round'
            />
        </SvgIcon>
    );
};

export const LargeLogoIcon = function (props) {
    return (
        <SvgIcon viewBox='0 0 163 20' sx={{ width: 163, height: 20 }} {...props}>
            <path d='M19.686 3.40629L19.9914 3.00549V17.9737C19.9914 19.0782 19.096 19.9737 17.9914 19.9737H8.42999L19.686 3.40629Z' fill='#276EF1' />
            <path d='M19.9749 2.59113H19.9996L4.60479 20.0002H2C0.89543 20.0002 0 19.1047 0 18.0002V10.392L19.9749 2.59113Z' fill='#276EF1' />
            <path d='M17.9897 0C19.0943 0 19.9897 0.89543 19.9897 2V2.02403L0.0474243 7.71482V2C0.0474243 0.895432 0.942855 0 2.04742 0H17.9897Z' fill='#276EF1' />
            <path d='M38.7362 17.9774C42.2905 17.9774 44.4824 16.1904 44.4824 13.3667V13.3568C44.4824 11.1354 43.1692 9.93088 40.158 9.30888L38.6474 9.00281C36.969 8.65726 36.2779 8.10436 36.2779 7.1763V7.16643C36.2779 6.10014 37.2553 5.42877 38.7264 5.4189C40.2271 5.4189 41.2144 6.12976 41.3822 7.14668L41.402 7.26516H44.2059L44.196 7.13681C44.0084 4.7574 42.0536 3 38.7264 3C35.567 3 33.2962 4.74753 33.2962 7.39351V7.40338C33.2962 9.56558 34.5698 10.9478 37.5317 11.5501L39.0324 11.8561C40.7997 12.2214 41.5007 12.7447 41.5007 13.6826V13.6925C41.5007 14.7884 40.4048 15.5486 38.8152 15.5486C37.1467 15.5486 35.9915 14.8279 35.8829 13.7616L35.8731 13.6629H33L33.0099 13.8209C33.1777 16.3484 35.33 17.9774 38.7362 17.9774Z' fill='#276EF1' />
            <path d='M53.2694 17.9774C56.9421 17.9774 59.2327 15.8547 59.2327 12.6953V3.3653H56.251V12.4288C56.251 14.275 55.1847 15.4499 53.2694 15.4499C51.354 15.4499 50.2778 14.275 50.2778 12.4288V3.3653H47.2962V12.6953C47.2962 15.8646 49.6163 17.9774 53.2694 17.9774Z' fill='#276EF1' />
            <path d='M62.6883 17.6121H65.6699V13.1199H68.5627C71.5641 13.1199 73.5683 11.1848 73.5683 8.25246V8.23271C73.5683 5.30042 71.5641 3.3653 68.5627 3.3653H62.6883V17.6121ZM67.8321 5.72496C69.5401 5.72496 70.5472 6.62341 70.5472 8.24259V8.26233C70.5472 9.88151 69.5401 10.7898 67.8321 10.7898H65.6699V5.72496H67.8321Z' fill='#276EF1' />
            <path d='M76.3131 17.6121H85.7517V15.1537H79.2947V11.5501H85.3864V9.25951H79.2947V5.82369H85.7517V3.3653H76.3131V17.6121Z' fill='#276EF1' />
            <path d='M88.8913 17.6121H91.873V12.3991H94.3116L96.9675 17.6121H100.344L97.3426 11.9549C98.9717 11.3131 99.9195 9.74329 99.9195 7.91678V7.89703C99.9195 5.09309 98.0634 3.3653 94.825 3.3653H88.8913V17.6121ZM91.873 10.2271V5.69534H94.4597C95.9505 5.69534 96.8687 6.58392 96.8687 7.95627V7.97602C96.8687 9.37799 95.99 10.2271 94.4992 10.2271H91.873Z' fill='#276EF1' />
            <path d='M102.931 17.6121H105.912V12.0042H111.708V9.64456H105.912V5.82369H112.261V3.3653H102.931V17.6121Z' fill='#276EF1' />
            <path d='M115.015 17.6121H124.336V15.1537H117.997V3.3653H115.015V17.6121Z' fill='#276EF1' />
            <path d='M127.041 17.6121H136.479V15.1537H130.022V11.5501H136.114V9.25951H130.022V5.82369H136.479V3.3653H127.041V17.6121Z' fill='#276EF1' />
            <path d='M139.619 17.6121H149.058V15.1537H142.601V11.5501H148.692V9.25951H142.601V5.82369H149.058V3.3653H139.619V17.6121Z' fill='#276EF1' />
            <path d='M155.653 17.6121H158.635V5.82369H162.91V3.3653H151.378V5.82369H155.653V17.6121Z' fill='#276EF1' />
        </SvgIcon>
    );
};


export const Feature1 = function (props) {
    return (
        <SvgIcon
            viewBox='0 0 32 32'
            {...props}
        >
            <path d='M24 13.3333H32L28 8L24 13.3333ZM24 18.6667H32L28 24L24 18.6667Z' fill='#D6B1FF' />
            <path d='M1.33333 0H9.33333V32H1.33333C0.979711 32 0.640572 31.8595 0.390523 31.6095C0.140475 31.3594 0 31.0203 0 30.6667V1.33333C0 0.979711 0.140475 0.640572 0.390523 0.390523C0.640572 0.140475 0.979711 0 1.33333 0Z' fill='#276EF1' />
            <path d='M12 0H20C20.3536 0 20.6928 0.140475 20.9428 0.390523C21.1929 0.640572 21.3333 0.979711 21.3333 1.33333V30.6667C21.3333 31.0203 21.1929 31.3594 20.9428 31.6095C20.6928 31.8595 20.3536 32 20 32H12V0Z' fill='#2EC5CE' />
        </SvgIcon>
    );
};

export const Feature2 = function (props) {
    return (
        <SvgIcon
            viewBox='0 0 32 32'
            {...props}
        >
            <path d='M5.33337 1.33331H30.6667L26.6667 9.33331H1.33337L5.33337 1.33331Z' fill='#276EF1' />
            <path d='M5.33337 12H30.6667L26.6667 20H1.33337L5.33337 12Z' fill='#2EC5CE' />
            <path d='M5.33337 22.6666H30.6667L26.6667 30.6666H1.33337L5.33337 22.6666Z' fill='#D6B1FF' />
        </SvgIcon>
    );
};

export const Feature3 = function (props) {
    return (
        <SvgIcon
            viewBox='0 0 32 32'
            {...props}
        >
            <path d='M30.6667 7.99998V2.66665C30.6667 2.31302 30.5262 1.97388 30.2762 1.72384C30.0261 1.47379 29.687 1.33331 29.3334 1.33331H2.66671C2.31309 1.33331 1.97395 1.47379 1.7239 1.72384C1.47385 1.97388 1.33337 2.31302 1.33337 2.66665V7.99998H30.6667Z' fill='#2EC5CE' />
            <path d='M1.33337 10.6666V29.3333C1.33337 29.6869 1.47385 30.0261 1.7239 30.2761C1.97395 30.5262 2.31309 30.6666 2.66671 30.6666H9.33337V10.6666H1.33337Z' fill='#D6B1FF' />
            <path d='M12 30.6666H29.3333C29.687 30.6666 30.0261 30.5262 30.2761 30.2761C30.5262 30.0261 30.6667 29.6869 30.6667 29.3333V10.6666H12V30.6666Z' fill='#276EF1' />
        </SvgIcon>
    );
};

export const Feature4 = function (props) {
    return (
        <SvgIcon
            viewBox='0 0 32 32'
            {...props}
        >
            <path d='M28 32H16C15.6463 32 15.3072 31.8595 15.0571 31.6095C14.8071 31.3594 14.6666 31.0203 14.6666 30.6667V28C14.6666 27.6464 14.8071 27.3073 15.0571 27.0572C15.3072 26.8072 15.6463 26.6667 16 26.6667H28C28.3536 26.6667 28.6927 26.8072 28.9428 27.0572C29.1928 27.3073 29.3333 27.6464 29.3333 28V30.6667C29.3333 31.0203 29.1928 31.3594 28.9428 31.6095C28.6927 31.8595 28.3536 32 28 32Z' fill='#D6B1FF' />
            <path d='M28 0H3.99996C3.64634 0 3.3072 0.140475 3.05715 0.390523C2.8071 0.640572 2.66663 0.979711 2.66663 1.33333V4C2.66663 4.35362 2.8071 4.69276 3.05715 4.94281C3.3072 5.19286 3.64634 5.33333 3.99996 5.33333H28C28.3536 5.33333 28.6927 5.19286 28.9428 4.94281C29.1928 4.69276 29.3333 4.35362 29.3333 4V1.33333C29.3333 0.979711 29.1928 0.640572 28.9428 0.390523C28.6927 0.140475 28.3536 0 28 0Z' fill='#2EC5CE' />
            <path d='M28 8H3.99996C3.64634 8 3.3072 8.14047 3.05715 8.39052C2.8071 8.64057 2.66663 8.97971 2.66663 9.33333V22.6667C2.66663 23.0203 2.8071 23.3594 3.05715 23.6095C3.3072 23.8595 3.64634 24 3.99996 24H28C28.3536 24 28.6927 23.8595 28.9428 23.6095C29.1928 23.3594 29.3333 23.0203 29.3333 22.6667V9.33333C29.3333 8.97971 29.1928 8.64057 28.9428 8.39052C28.6927 8.14047 28.3536 8 28 8Z' fill='#276EF1' />
        </SvgIcon>
    );
};

export const Feature5 = function (props) {
    return (
        <SvgIcon
            viewBox='0 0 32 32'
            {...props}
        >
            <path d='M6.66671 1.33331H2.66671C2.31309 1.33331 1.97395 1.47379 1.7239 1.72384C1.47385 1.97388 1.33337 2.31302 1.33337 2.66665V29.3333C1.33337 29.6869 1.47385 30.0261 1.7239 30.2761C1.97395 30.5262 2.31309 30.6666 2.66671 30.6666H6.66671V1.33331Z' fill='#D6B1FF' />
            <path d='M9.33337 30.6666H22.6667V1.33331H9.33337V30.6666ZM16 25.3333C15.6464 25.3333 15.3073 25.1928 15.0572 24.9428C14.8072 24.6927 14.6667 24.3536 14.6667 24C14.6667 23.6464 14.8072 23.3072 15.0572 23.0572C15.3073 22.8071 15.6464 22.6666 16 22.6666C16.3537 22.6666 16.6928 22.8071 16.9429 23.0572C17.1929 23.3072 17.3334 23.6464 17.3334 24C17.3334 24.3536 17.1929 24.6927 16.9429 24.9428C16.6928 25.1928 16.3537 25.3333 16 25.3333ZM16 6.66665C16.3537 6.66665 16.6928 6.80712 16.9429 7.05717C17.1929 7.30722 17.3334 7.64636 17.3334 7.99998C17.3334 8.3536 17.1929 8.69274 16.9429 8.94279C16.6928 9.19284 16.3537 9.33331 16 9.33331C15.6464 9.33331 15.3073 9.19284 15.0572 8.94279C14.8072 8.69274 14.6667 8.3536 14.6667 7.99998C14.6667 7.64636 14.8072 7.30722 15.0572 7.05717C15.3073 6.80712 15.6464 6.66665 16 6.66665ZM16 14.6666C16.3537 14.6666 16.6928 14.8071 16.9429 15.0572C17.1929 15.3072 17.3334 15.6464 17.3334 16C17.3334 16.3536 17.1929 16.6927 16.9429 16.9428C16.6928 17.1928 16.3537 17.3333 16 17.3333C15.6464 17.3333 15.3073 17.1928 15.0572 16.9428C14.8072 16.6927 14.6667 16.3536 14.6667 16C14.6667 15.6464 14.8072 15.3072 15.0572 15.0572C15.3073 14.8071 15.6464 14.6666 16 14.6666Z' fill='#276EF1' />
            <path d='M29.3334 1.33331H25.3334V30.6666H29.3334C29.687 30.6666 30.0261 30.5262 30.2762 30.2761C30.5262 30.0261 30.6667 29.6869 30.6667 29.3333V2.66665C30.6667 2.31302 30.5262 1.97388 30.2762 1.72384C30.0261 1.47379 29.687 1.33331 29.3334 1.33331Z' fill='#2EC5CE' />
        </SvgIcon>
    );
};

export const Feature6 = function (props) {
    return (
        <SvgIcon
            viewBox='0 0 32 32'
            {...props}
        >
            <path d='M15.2081 0.175992L0.781403 6.91599C0.683842 6.96073 0.601194 7.03256 0.543297 7.12294C0.4854 7.21331 0.454694 7.31842 0.454834 7.42575C0.454975 7.53308 0.485955 7.63811 0.544088 7.72833C0.602222 7.81856 0.685058 7.89017 0.782736 7.93466L15.3227 14.6253C15.7521 14.8227 16.2481 14.8227 16.6774 14.6253L31.2174 7.93466C31.3151 7.8903 31.3981 7.81879 31.4563 7.72865C31.5146 7.6385 31.5457 7.53351 31.546 7.42618C31.5463 7.31885 31.5157 7.2137 31.4579 7.12325C31.4001 7.0328 31.3176 6.96086 31.2201 6.91599L16.7921 0.175992C16.5441 0.0601174 16.2738 6.10352e-05 16.0001 6.10352e-05C15.7264 6.10352e-05 15.456 0.0601174 15.2081 0.175992Z' fill='#276EF1' />
            <path opacity='0.64' d='M31.22 15.568L28.24 14.176C28.0266 14.0763 27.794 14.0243 27.5584 14.0236C27.3229 14.0229 27.09 14.0735 26.876 14.172L16.6773 18.8653C16.4649 18.963 16.2338 19.0136 16 19.0136C15.7662 19.0136 15.5351 18.963 15.3227 18.8653L5.12267 14.172C4.90885 14.0737 4.67621 14.0232 4.4409 14.0239C4.20559 14.0246 3.97324 14.0765 3.76001 14.176L0.780006 15.568C0.682503 15.6128 0.599949 15.6848 0.54217 15.7752C0.48439 15.8657 0.453823 15.9708 0.454103 16.0782C0.454384 16.1855 0.485502 16.2905 0.543754 16.3806C0.602006 16.4708 0.684937 16.5423 0.782674 16.5866L15.3227 23.276C15.535 23.3742 15.7661 23.4251 16 23.4251C16.2339 23.4251 16.465 23.3742 16.6773 23.276L31.2173 16.5866C31.3151 16.5423 31.398 16.4708 31.4563 16.3806C31.5145 16.2905 31.5456 16.1855 31.5459 16.0782C31.5462 15.9708 31.5156 15.8657 31.4578 15.7752C31.4001 15.6848 31.3175 15.6128 31.22 15.568Z' fill='#D6B1FF' />
            <path d='M31.22 24.144L28.24 22.752C28.0266 22.6522 27.794 22.6002 27.5585 22.5995C27.3229 22.5989 27.09 22.6495 26.876 22.748L16.6774 27.44C16.4649 27.5376 16.2338 27.5882 16 27.5882C15.7662 27.5882 15.5351 27.5376 15.3227 27.44L5.12269 22.7466C4.90887 22.6484 4.67623 22.5978 4.44091 22.5985C4.2056 22.5992 3.97326 22.6511 3.76002 22.7506L0.780019 24.144C0.682891 24.189 0.600708 24.2609 0.543205 24.3512C0.485701 24.4415 0.455288 24.5464 0.455568 24.6535C0.455849 24.7605 0.486813 24.8653 0.544789 24.9553C0.602765 25.0453 0.685324 25.1168 0.782686 25.1613L15.3227 31.852C15.752 32.0493 16.248 32.0493 16.6774 31.852L31.2174 25.1613C31.3147 25.1168 31.3973 25.0453 31.4553 24.9553C31.5132 24.8653 31.5442 24.7605 31.5445 24.6535C31.5448 24.5464 31.5143 24.4415 31.4568 24.3512C31.3993 24.2609 31.3172 24.189 31.22 24.144Z' fill='#2EC5CE' />
        </SvgIcon>
    );
};

